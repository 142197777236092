import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Input,
  Select,
  Button,
  VStack,
  Box,
  Flex,
  FormControl,
  FormLabel,
  useBreakpointValue,
  Checkbox,
  useToast,
  Divider,
} from "@chakra-ui/react";
import { ChevronDownIcon, CopyIcon, CloseIcon } from "@chakra-ui/icons";
import { useRef, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { RecipientDto } from "../../../../generated-client/model/recipient-dto";
import { recipientsService } from "../../../../services/recipients/recipients";
import { getExchangeRate } from "../../../../services/finance/finance";
import { PaymentDto } from "../../../../generated-client/model/payment-dto";
import { uploadFile } from "services/file";
import { CreateDirectPaymentRequest } from "generated-client/model/create-direct-payment-request";
import {
  attachInvoice,
  attachProofOfUserPayment,
  createDirectPayment,
} from "services/direct-payment";
import { createPaymentRequest } from "services/payment-request";
import { CreatePaymentRequestRequest } from "generated-client/model/create-payment-request-request";
import { getCompany } from "services/company/company";

interface PaymentHeaderProps {
  currentStep: number;
  onStepClick: (step: number) => void;
  completedSteps: number[];
}

const PaymentHeader: React.FC<PaymentHeaderProps> = ({
  currentStep,
  onStepClick,
  completedSteps,
}) => {
  const StepText = ({ step, text }: { step: number; text: string }) => (
    <Text
      fontWeight={currentStep === step ? "bold" : "normal"}
      cursor={
        completedSteps.includes(step - 1) || step === 1
          ? "pointer"
          : "not-allowed"
      }
      onClick={() => {
        if (completedSteps.includes(step - 1) || step === 1) {
          onStepClick(step);
        }
      }}
      color={
        completedSteps.includes(step - 1) || step === 1
          ? "gray.600"
          : "gray.400"
      }
      _hover={{
        textDecoration:
          completedSteps.includes(step - 1) || step === 1
            ? "underline"
            : "none",
      }}
    >
      {text}
    </Text>
  );

  return (
    <Box mb={6}>
      <Text fontSize="2xl" fontWeight="bold" mb={2}>
        Payment to supplier
      </Text>
      <Flex gap={2} align="center" color="gray.600" fontSize="sm">
        <StepText step={1} text="Payment details" />
        <Text>{">"}</Text>
        <StepText step={2} text="Review" />
        <Text>{">"}</Text>
        <StepText step={3} text="Fund payment" />
      </Flex>
    </Box>
  );
};

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPaymentComplete?: () => void;
}

interface PaymentDetails {
  amount: string;
  recipientId?: string;
  supplierName: string;
  bankName: string;
  accountNumber: string;
  file?: File;
}

const copyToClipboard = async (
  text: string,
  toast: ReturnType<typeof useToast>
) => {
  try {
    await navigator.clipboard.writeText(text);
    toast({
      title: "Account number copied",
      description: "Account number has been copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  } catch (err) {
    toast({
      title: "Failed to copy",
      description: "Could not copy account number to clipboard",
      status: "error",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
  }
};

interface CountryOption {
  value: string;
  label: string;
}

export const PaymentFlow: React.FC<PaymentModalProps> = ({
  isOpen,
  onClose,
  onPaymentComplete,
}) => {
  const toast = useToast();
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [step, setStep] = useState<number>(1);
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>({
    amount: "",
    supplierName: "",
    bankName: "",
    accountNumber: "",
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [rawAmount, setRawAmount] = useState<string>("");
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [flexPayAmount, setFlexPayAmount] = useState<string>("");
  const [recipients, setRecipients] = useState<RecipientDto[]>([]);
  const [selectedRecipient, setSelectedRecipient] = useState<string>("");
  const { getAccessTokenSilently } = useAuth0();

  const MONTHLY_INTEREST_RATE = 0.04;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const proofFileInputRef = useRef<HTMLInputElement>(null);

  const [isNewRecipient, setIsNewRecipient] = useState(false);
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const [isLoadingRate, setIsLoadingRate] = useState(false);
  const [ngnAmount, setNgnAmount] = useState<string>("");
  const [isCreatingPayment, setIsCreatingPayment] = useState(false);
  const [isLoadingRecipients, setIsLoadingRecipients] =
    useState<boolean>(false);
  const [selectedProofFile, setSelectedProofFile] = useState<File | null>(null);
  const [repaymentPeriod, setRepaymentPeriod] = useState<number>(1);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(
    null
  );
  const [hasRecipientChanged, setHasRecipientChanged] = useState(false);
  const [originalRecipientDetails, setOriginalRecipientDetails] = useState<{
    name: string;
    bankName: string;
    accountNumber: string;
    country?: string;
  } | null>(null);
  const [creditLimit, setCreditLimit] = useState<number>(0);

  const countryOptions: CountryOption[] = [
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "China", label: "China" },
    { value: "United States of America", label: "United States of America" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "Canada", label: "Canada" },
    { value: "Brazil", label: "Brazil" },
    { value: "India", label: "India" },
    { value: "Netherlands", label: "Netherlands" },
  ];

  const calculateInterest = (amount: number, months: number) => {
    return amount * MONTHLY_INTEREST_RATE * months;
  };

  const calculateTotalToRepay = (amount: number, interest: number) => {
    return amount + interest;
  };

  const calculateMonthlyPayment = (totalAmount: number, months: number) => {
    if (months === 0) return 0;
    // Round to 2 decimal places to avoid floating point issues
    return Math.round((totalAmount / months) * 100) / 100;
  };

  const handleProofFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedProofFile(file);
      setPaymentDetails((prev) => ({
        ...prev,
        proofOfPayment: file,
      }));
    }
  };

  const triggerProofFileUpload = () => {
    proofFileInputRef.current?.click();
  };

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        setIsLoadingRate(true);
        const accessToken = await getAccessTokenSilently();
        const response = await getExchangeRate(accessToken, "USD", "NGN");
        const data = await getCompany(accessToken);
        setExchangeRate(response.exchangeRate.value);
        setCreditLimit(data.company?.creditInformation?.creditLimit || 0);
      } catch (error) {
        console.error("Failed to fetch exchange rate:", error);
      } finally {
        setIsLoadingRate(false);
      }
    };

    if (isOpen) {
      fetchExchangeRate();
    }
  }, [isOpen, getAccessTokenSilently]);

  const createAndSelectRecipient = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const newRecipient = await recipientsService.createRecipient(
        accessToken,
        {
          name: paymentDetails.supplierName,
          bankName: paymentDetails.bankName,
          accountNumber: paymentDetails.accountNumber,
          country: selectedCountry?.value,
        }
      );

      const updatedRecipients = await recipientsService.getRecipients(
        accessToken
      );
      setRecipients(updatedRecipients);

      setSelectedRecipient(newRecipient.id);
      setIsNewRecipient(false);

      return newRecipient.id;
    } catch (error) {
      console.error("Failed to create recipient:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchRecipients = async () => {
      try {
        setIsLoadingRecipients(true);
        const accessToken = await getAccessTokenSilently();
        const data = await recipientsService.getRecipients(accessToken);
        setRecipients(data);
      } catch (error) {
        console.error("Failed to fetch recipients:", error);
      } finally {
        setIsLoadingRecipients(false);
      }
    };

    if (isOpen) {
      fetchRecipients();
    }
  }, [isOpen, getAccessTokenSilently]);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setPaymentDetails((prev) => ({
        ...prev,
        file,
      }));
    }
  };

  const triggerFileUpload = () => {
    fileInputRef.current?.click();
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const formatAmount = (value: string): string => {
    // Remove non-numeric characters
    const numbers = value.replace(/[^0-9]/g, "");

    // Convert to number and format with commas
    const formatted = parseInt(numbers).toLocaleString("en-US");

    return isNaN(parseInt(numbers)) ? "" : formatted;
  };

  const validateStep1 = () => {
    if (!paymentDetails.amount || !selectedFile || !selectedCountry)
      return false;

    if (isNewRecipient) {
      return (
        paymentDetails.supplierName !== "" &&
        paymentDetails.bankName !== "" &&
        paymentDetails.accountNumber !== ""
      );
    }

    return selectedRecipient !== "";
  };

  const validateStep2 = () => {
    return paymentMethod !== "";
  };

  const handleStepClick = (newStep: number) => {
    if (newStep <= step || completedSteps.includes(newStep - 1)) {
      setStep(newStep);
    }
  };

  const handleNextStep = async () => {
    if (step === 1 && validateStep1()) {
      if (isNewRecipient) {
        const newRecipientId = await createAndSelectRecipient();
        if (!newRecipientId) {
          // Handle error - maybe show a toast
          return;
        }
        setPaymentDetails((prev) => ({
          ...prev,
          recipientId: newRecipientId,
        }));
      }
      setStep(2);
      setCompletedSteps([...completedSteps, 1]);
    } else if (step === 2 && validateStep2()) {
      setStep(3);
      setCompletedSteps([...completedSteps, 2]);
    }
  };

  const handleEditDetails = () => {
    setStep(1);
  };

  const handleFlexPayAmountChange = (value: string) => {
    const numbers = value.replace(/[^0-9]/g, "");
    const amount = parseInt(numbers) || 0;

    if (amount <= creditLimit) {
      const formatted = formatAmount(numbers);
      setFlexPayAmount(formatted);
    }
  };

  const handleAmountChange = (value: string) => {
    const formatted = formatAmount(value);
    setRawAmount(formatted);

    // Calculate NGN amount
    const usdAmount = parseFloat(value.replace(/[^0-9]/g, "")) || 0;
    const calculatedNgnAmount = (usdAmount * exchangeRate).toString();
    setNgnAmount(formatAmount(calculatedNgnAmount));

    setPaymentDetails((prev) => ({
      ...prev,
      amount: formatted.replace(/,/g, ""),
    }));
  };

  const handleCreatePayment = async () => {
    try {
      setIsCreatingPayment(true);
      const accessToken = await getAccessTokenSilently();

      // Update recipient if details changed
      if (hasRecipientChanged && selectedRecipient) {
        await recipientsService.updateRecipient(accessToken, {
          id: selectedRecipient,
          name: paymentDetails.supplierName,
          bankName: paymentDetails.bankName,
          accountNumber: paymentDetails.accountNumber,
          country: selectedCountry?.value,
        });
      }

      // If new recipient, include country
      if (isNewRecipient) {
        const newRecipient = await recipientsService.createRecipient(
          accessToken,
          {
            name: paymentDetails.supplierName,
            bankName: paymentDetails.bankName,
            accountNumber: paymentDetails.accountNumber,
            country: selectedCountry?.value,
          }
        );
        setPaymentDetails((prev) => ({
          ...prev,
          recipientId: newRecipient.id,
        }));
      }

      let createdPaymentRequest;
      if (paymentMethod === "flexPay") {
        // Create payment request first for flex pay
        createdPaymentRequest = await createPaymentRequest(accessToken, {
          amount: parseInt(flexPayAmount.replace(/,/g, "")),
          amountCurrency:
            "NGN" as CreatePaymentRequestRequest.AmountCurrencyEnum,
          amountInSecondaryCurrency: 0,
          amountInSecondaryCurrencyCurrency:
            "NGN" as CreatePaymentRequestRequest.AmountCurrencyEnum,
          amountCredit: parseInt(flexPayAmount.replace(/,/g, "")),
          durationInDays: repaymentPeriod * 30 + 1,
          items: [],
          type: CreatePaymentRequestRequest.TypeEnum.FlexPay,
          paymentMethod: CreatePaymentRequestRequest.PaymentMethodEnum.Transfer,
          supplierName: `${paymentDetails.supplierName} (${paymentDetails.accountNumber})`,
          supplierId: null,
          termsAccepted: termsAccepted,
        });
      }

      const directPaymentRequest: CreateDirectPaymentRequest = {
        amount: parseInt(rawAmount.replace(/,/g, "")),
        amountCurrency: "USD",
        amountToPayCurrency: "NGN",
        paymentMethod:
          paymentMethod === "bankTransfer"
            ? PaymentDto.PaymentMethodEnum.BankTransfer
            : paymentMethod === "cashDeposit"
            ? PaymentDto.PaymentMethodEnum.CashDeposit
            : PaymentDto.PaymentMethodEnum.FlexPay,
        recipientId: paymentDetails.recipientId!,
        country: "NG",
        amountToPay: parseInt(ngnAmount.replace(/,/g, "")),
        type:
          paymentMethod === "bankTransfer" || paymentMethod === "cashDeposit"
            ? CreateDirectPaymentRequest.TypeEnum.PayNowSupplier
            : CreateDirectPaymentRequest.TypeEnum.FlexPay,
        termsAccepted: termsAccepted,
        exchangeRate: exchangeRate,
        paymentRequestId: createdPaymentRequest?.paymentRequestId,
      };

      const { directPayment } = await createDirectPayment(
        accessToken,
        directPaymentRequest
      );

      if (paymentDetails.file) {
        const fileResponse = await uploadFile(
          accessToken,
          {
            directPaymentId: directPayment.id,
            type: "INVOICE",
          },
          paymentDetails.file
        );

        // Attach invoice to payment
        await attachInvoice(accessToken, {
          id: directPayment.id,
          fileId: fileResponse.id,
        });
      }

      // Upload proof of payment if present
      if (selectedProofFile) {
        const proofResponse = await uploadFile(
          accessToken,
          {
            directPaymentId: directPayment.id,
            type: "PROOF_OF_USER_PAYMENT",
          },
          selectedProofFile
        );

        // Attach proof of payment to payment
        await attachProofOfUserPayment(accessToken, {
          id: directPayment.id,
          fileId: proofResponse.id,
        });
      }

      onPaymentComplete?.();
      onClose();

      // Reset to step one and clear all data after successful submission
      setStep(1);
      setPaymentDetails({
        amount: "",
        supplierName: "",
        bankName: "",
        accountNumber: "",
      });
      setSelectedFile(null);
      setSelectedProofFile(null);
      setRawAmount("");
      setCompletedSteps([]);
      setPaymentMethod("");
      setSelectedRecipient("");
      setIsNewRecipient(false);
      setFlexPayAmount("");
      setNgnAmount("");
      setSelectedCountry(null);
      setHasRecipientChanged(false);
      setOriginalRecipientDetails(null);
    } catch (error) {
      console.error("Failed to create payment:", error);
      // You might want to show an error toast here
    } finally {
      setIsCreatingPayment(false);
    }
  };

  const resetForm = () => {
    setStep(1);
    setPaymentDetails({
      amount: "",
      supplierName: "",
      bankName: "",
      accountNumber: "",
    });
    setSelectedFile(null);
    setSelectedProofFile(null);
    setRawAmount("");
    setCompletedSteps([]);
    setPaymentMethod("");
    setSelectedRecipient("");
    setIsNewRecipient(false);
    setFlexPayAmount("");
    setNgnAmount("");
    setSelectedCountry(null);
    setHasRecipientChanged(false);
    setOriginalRecipientDetails(null);
  };

  const handleModalClose = () => {
    resetForm();
    onClose();
  };

  const handleRecipientSelect = (recipientId: string) => {
    const recipient = recipients.find((r) => r.id === recipientId);
    if (recipient) {
      setOriginalRecipientDetails({
        name: recipient.name,
        bankName: recipient.bankName,
        accountNumber: recipient.accountNumber,
        country: recipient.country,
      });
      setPaymentDetails((prev) => ({
        ...prev,
        supplierName: recipient.name,
        bankName: recipient.bankName,
        accountNumber: recipient.accountNumber,
        recipientId: recipient.id,
      }));

      // Set the country if it exists in the recipient data
      if (recipient.country) {
        const countryOption = countryOptions.find(
          (option) => option.value === recipient.country
        );
        if (countryOption) {
          setSelectedCountry(countryOption);
        }
      }
    }
  };

  const checkRecipientChanged = (newDetails: typeof paymentDetails) => {
    if (!originalRecipientDetails) return false;

    const currentCountry = selectedCountry?.value;
    const originalCountry = recipients.find(
      (r) => r.id === selectedRecipient
    )?.country;

    return (
      newDetails.supplierName !== originalRecipientDetails.name ||
      newDetails.bankName !== originalRecipientDetails.bankName ||
      newDetails.accountNumber !== originalRecipientDetails.accountNumber ||
      currentCountry !== originalCountry
    );
  };

  const renderRecipientSection = () => (
    <Box>
      <FormControl mb={4}>
        <FormLabel fontSize="md" color="gray.700" fontWeight="normal">
          Select recipient
        </FormLabel>
        <Select
          placeholder="Select a recipient"
          icon={<ChevronDownIcon />}
          borderColor="gray.200"
          borderRadius="lg"
          size="lg"
          value={selectedRecipient}
          onChange={(e) => {
            const value = e.target.value;
            setIsNewRecipient(false);
            setSelectedRecipient(value);
            handleRecipientSelect(value);
          }}
          _hover={{ borderColor: "gray.300" }}
          isDisabled={isNewRecipient}
        >
          {isLoadingRecipients ? (
            <option value="" disabled>
              Loading recipients...
            </option>
          ) : recipients.length > 0 ? (
            recipients.map((recipient) => (
              <option key={recipient.id} value={recipient.id}>
                {recipient.name} *{recipient.accountNumber.slice(-4)}
              </option>
            ))
          ) : (
            <option value="" disabled>
              No recipients found
            </option>
          )}
        </Select>
      </FormControl>

      <Button
        variant="outline"
        size="lg"
        width="100%"
        mb={6}
        onClick={() => {
          setIsNewRecipient(!isNewRecipient);
          if (!isNewRecipient) {
            setSelectedRecipient("");
            setPaymentDetails((prev) => ({
              ...prev,
              supplierName: "",
              bankName: "",
              accountNumber: "",
              recipientId: undefined,
            }));
            setSelectedCountry(null);
          }
        }}
        leftIcon={
          <Text as="span" fontSize="lg" mr={1}>
            {isNewRecipient ? "×" : "+"}
          </Text>
        }
      >
        {isNewRecipient ? "Cancel" : "Add new recipient"}
      </Button>

      {(isNewRecipient || selectedRecipient) && (
        <Box>
          <Text fontSize="md" color="gray.700" mb={4}>
            {isNewRecipient ? "Enter recipient details" : "Recipient details"}
          </Text>
          <Box>
            <Input
              placeholder="Account name"
              mb={4}
              value={paymentDetails.supplierName}
              onChange={(e) => {
                const newDetails = {
                  ...paymentDetails,
                  supplierName: e.target.value,
                };
                setPaymentDetails(newDetails);
                setHasRecipientChanged(checkRecipientChanged(newDetails));
              }}
              borderColor="gray.200"
              borderRadius="lg"
              size="lg"
            />
            <Input
              placeholder="Bank name"
              mb={4}
              value={paymentDetails.bankName}
              onChange={(e) => {
                const newDetails = {
                  ...paymentDetails,
                  bankName: e.target.value,
                };
                setPaymentDetails(newDetails);
                setHasRecipientChanged(checkRecipientChanged(newDetails));
              }}
              borderColor="gray.200"
              borderRadius="lg"
              size="lg"
            />
            <Input
              placeholder="Bank account number"
              mb={4}
              value={paymentDetails.accountNumber}
              onChange={(e) => {
                const newDetails = {
                  ...paymentDetails,
                  accountNumber: e.target.value,
                };
                setPaymentDetails(newDetails);
                setHasRecipientChanged(checkRecipientChanged(newDetails));
              }}
              borderColor="gray.200"
              borderRadius="lg"
              size="lg"
            />
            <FormControl isRequired>
              <Select
                placeholder="Select country"
                value={selectedCountry?.value || ""}
                onChange={(e) => {
                  const country = countryOptions.find(
                    (option) => option.value === e.target.value
                  );
                  setSelectedCountry(country || null);
                  if (!isNewRecipient) {
                    setHasRecipientChanged(true);
                  }
                }}
                borderColor="gray.200"
                borderRadius="lg"
                size="lg"
                mb={4}
                sx={{
                  "&::placeholder": {
                    color: "gray.500",
                  },
                  "option:first-of-type": {
                    color: "gray.500",
                  },
                }}
                _placeholder={{ color: "gray.500" }}
              >
                {countryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}
    </Box>
  );

  const renderAmountSection = () => (
    <FormControl mb={6} maxW="320px">
      <FormLabel fontSize="md" color="gray.700" fontWeight="normal">
        Enter amount
      </FormLabel>
      <Box
        borderWidth="1px"
        borderColor="#cbcbcb"
        borderRadius="lg"
        p={3}
        bg="white"
      >
        <Flex align="center">
          <Text color="gray.600" fontSize="md" fontWeight="semibold">
            USD
          </Text>
          <Text color="gray.600" mx={2} fontWeight="semibold">
            |
          </Text>
          <Input
            variant="unstyled"
            textAlign="right"
            value={rawAmount}
            placeholder="0"
            onChange={(e) => handleAmountChange(e.target.value)}
            flex={1}
            fontWeight="semibold"
          />
        </Flex>
      </Box>
      <Box
        mt={2}
        px={3}
        py={2}
        borderRadius="lg"
        borderWidth="1px"
        borderColor="#cbcbcb"
        bg="white"
      >
        <Flex justify="space-between">
          <Text color="brand.500" fontWeight="semibold" fontSize="lg">
            FX rate
          </Text>
          <Text fontWeight="normal">
            {isLoadingRate
              ? "Loading..."
              : `$1 = ₦${exchangeRate.toLocaleString()}`}
          </Text>
        </Flex>
        <Flex justify="space-between">
          <Text color="brand.500" fontWeight="semibold" fontSize="lg">
            You pay
          </Text>
          <Text fontWeight="normal">₦{ngnAmount ? ngnAmount : "0"}</Text>
        </Flex>
      </Box>
    </FormControl>
  );

  const renderInitialPaymentStep = () => (
    <Box position="relative">
      <Box p={8} pb={isMobile ? 24 : 8}>
        <PaymentHeader
          currentStep={step}
          onStepClick={handleStepClick}
          completedSteps={completedSteps}
        />
        <Flex gap={8} direction={{ base: "column", md: "row" }}>
          {/* Left Column */}
          <Box flex={1}>
            {renderAmountSection()}

            <FormControl maxW="320px">
              <FormLabel fontSize="md" color="gray.700" fontWeight="normal">
                Upload invoice *
              </FormLabel>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileUpload}
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
              />
              <Box
                borderWidth={1}
                borderStyle="dashed"
                borderColor="gray.200"
                borderRadius="lg"
                p={8}
                textAlign="center"
                cursor="pointer"
                onClick={triggerFileUpload}
                _hover={{ bg: "gray.50" }}
              >
                {selectedFile ? (
                  <Box>
                    <Text>✓ File uploaded</Text>
                    <Text fontSize="sm" color="gray.500">
                      {selectedFile.name}
                    </Text>
                  </Box>
                ) : (
                  <Box>
                    <Text mb={2}>↑</Text>
                    <Text color="gray.600">Click to upload invoice</Text>
                  </Box>
                )}
              </Box>
            </FormControl>
          </Box>

          {/* Right Column */}
          <Box flex={1}>{renderRecipientSection()}</Box>
        </Flex>
      </Box>

      {/* Fixed Button */}
      <Box
        position={isMobile ? "fixed" : "absolute"}
        bottom={!isMobile ? -8 : 0}
        left={isMobile ? 0 : "auto"}
        right={0}
        p={isMobile ? 4 : 8}
        pb={isMobile ? 4 : 4}
        pt={isMobile ? 4 : 4}
        width={isMobile ? "100%" : "auto"}
        bg="white"
        borderTop={isMobile ? "1px solid" : "none"}
        borderColor="gray.200"
        zIndex={2}
      >
        <Button
          bg="black"
          color="white"
          size="lg"
          width={isMobile ? "100%" : "auto"}
          px={isMobile ? 4 : 12}
          _hover={{ bg: "gray.800" }}
          onClick={handleNextStep}
          isDisabled={!validateStep1()}
        >
          Next
        </Button>
      </Box>
    </Box>
  );

  const renderPaymentMethodStep = () => (
    <Box position="relative">
      <Box p={8} pb={isMobile ? 24 : 8}>
        <PaymentHeader
          currentStep={step}
          onStepClick={handleStepClick}
          completedSteps={completedSteps}
        />
        <Flex gap={8}>
          {/* Left Column */}
          <Box flex={1} maxW="200px">
            <Box mb={8}>
              <Text fontWeight="semibold" mb={2} color="gray.500">
                Recipient details
              </Text>
              <VStack align="start" spacing={1}>
                <Text>{paymentDetails.supplierName}</Text>
                <Text>{paymentDetails.bankName}</Text>
                <Text>{paymentDetails.accountNumber}</Text>
                <Button
                  variant="link"
                  color="blue.500"
                  size="sm"
                  mt={2}
                  fontWeight="normal"
                  textDecoration="underline"
                  onClick={handleEditDetails}
                >
                  Edit details
                </Button>
              </VStack>
            </Box>

            {/* Payment details - shows under recipient details only on mobile */}
            <Box display={{ base: "block", md: "none" }} mb={8}>
              <Text fontWeight="semibold" mb={2} color="gray.500">
                Payment details
              </Text>
              <VStack align="start" spacing={1}>
                <Text>Recipient receives: ${rawAmount}</Text>
                <Text>Estimated arrival: 24 hours</Text>
                <Text>You pay: ₦{ngnAmount}</Text>
              </VStack>
            </Box>
          </Box>

          {/* Right Column - hidden on mobile */}
          <Box flex={1} display={{ base: "none", md: "block" }}>
            <Box mb={8}>
              <Text fontWeight="semibold" mb={2} color="gray.500">
                Payment details
              </Text>
              <VStack align="start" spacing={1}>
                <Text>Recipient receives: ${rawAmount}</Text>
                <Text>Estimated arrival: 24 hours</Text>
                <Text>You pay: NGN {ngnAmount}</Text>
              </VStack>
            </Box>
          </Box>
        </Flex>

        {/* Desktop payment method buttons */}
        <Box display={{ base: "none", md: "block" }} mb={8}>
          <Text fontWeight="semibold" mb={4}>
            Select payment method
          </Text>
          <Flex gap={3}>
            <Box
              py={2}
              px={3}
              minW="200px"
              border="1px solid"
              borderColor={
                paymentMethod === "bankTransfer" ? "purple.500" : "gray.200"
              }
              borderRadius="md"
              bg={paymentMethod === "bankTransfer" ? "purple.50" : "white"}
              cursor="pointer"
              onClick={() => setPaymentMethod("bankTransfer")}
              display="flex"
              alignItems="center"
              height="56px"
            >
              <Text>Bank transfer</Text>
            </Box>

            <Box
              py={2}
              px={3}
              minW="200px"
              border="1px solid"
              borderColor={
                paymentMethod === "cashDeposit" ? "purple.500" : "gray.200"
              }
              borderRadius="md"
              bg={paymentMethod === "cashDeposit" ? "purple.50" : "white"}
              cursor="pointer"
              onClick={() => setPaymentMethod("cashDeposit")}
              display="flex"
              alignItems="center"
              height="56px"
            >
              <Text>Cash deposit</Text>
            </Box>

            <Box
              py={2}
              px={3}
              minW="200px"
              border="1px solid"
              borderColor={
                paymentMethod === "flexPay" ? "purple.500" : "gray.200"
              }
              borderRadius="md"
              bg={paymentMethod === "flexPay" ? "purple.50" : "white"}
              cursor="pointer"
              onClick={() => setPaymentMethod("flexPay")}
              height="56px"
            >
              <Flex align="center" height="100%">
                <VStack align="start" spacing={0}>
                  <Text>Pay later</Text>
                  <Text fontSize="sm" color="gray.500">
                    Credit limit: ₦{formatAmount(creditLimit.toString())}
                  </Text>
                </VStack>
              </Flex>
            </Box>
          </Flex>
        </Box>

        {/* Mobile payment method buttons */}
        <Box display={{ base: "block", md: "none" }} mb={8}>
          <Text fontWeight="semibold" mb={4}>
            Select payment method
          </Text>
          <Flex gap={4} wrap="wrap">
            <Box
              flex="1 0 100%"
              py={2}
              px={3}
              border="1px solid"
              borderColor={
                paymentMethod === "bankTransfer" ? "purple.500" : "gray.200"
              }
              borderRadius="md"
              bg={paymentMethod === "bankTransfer" ? "purple.50" : "white"}
              cursor="pointer"
              onClick={() => setPaymentMethod("bankTransfer")}
              display="flex"
              alignItems="center"
              height="48px"
            >
              <Text>Bank transfer</Text>
            </Box>

            <Box
              flex="1 0 100%"
              py={2}
              px={3}
              border="1px solid"
              borderColor={
                paymentMethod === "cashDeposit" ? "purple.500" : "gray.200"
              }
              borderRadius="md"
              bg={paymentMethod === "cashDeposit" ? "purple.50" : "white"}
              cursor="pointer"
              onClick={() => setPaymentMethod("cashDeposit")}
              display="flex"
              alignItems="center"
              height="48px"
            >
              <Text>Cash deposit</Text>
            </Box>

            <Box
              flex="1 0 100%"
              py={2}
              px={3}
              border="1px solid"
              borderColor={
                paymentMethod === "flexPay" ? "purple.500" : "gray.200"
              }
              borderRadius="md"
              bg={paymentMethod === "flexPay" ? "purple.50" : "white"}
              cursor="pointer"
              onClick={() => setPaymentMethod("flexPay")}
              height="56px"
            >
              <Flex align="center" height="100%">
                <VStack align="start" spacing={0}>
                  <Text>Pay later</Text>
                  <Text fontSize="sm" color="gray.500">
                    Credit limit: ₦{formatAmount(creditLimit.toString())}
                  </Text>
                </VStack>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>

      <Box
        position={isMobile ? "fixed" : "absolute"}
        bottom={!isMobile ? -8 : 0}
        left={isMobile ? 0 : "auto"}
        right={0}
        p={isMobile ? 4 : 8}
        pb={isMobile ? 4 : 4}
        pt={isMobile ? 4 : 4}
        width={isMobile ? "100%" : "auto"}
        bg="white"
        borderTop={isMobile ? "1px solid" : "none"}
        borderColor="gray.200"
        zIndex={2}
      >
        <Button
          bg="black"
          color="white"
          size="lg"
          width={isMobile ? "100%" : "auto"}
          px={isMobile ? 4 : 12}
          _hover={{ bg: "gray.800" }}
          onClick={handleNextStep}
          isDisabled={!validateStep2()}
        >
          Next
        </Button>
      </Box>
    </Box>
  );

  const calculateRemainingAmount = (
    totalAmount: string,
    flexAmount: string
  ) => {
    const total = parseInt(totalAmount.replace(/,/g, "") || "0");
    const flex = parseInt(flexAmount.replace(/,/g, "") || "0");
    return total - flex;
  };

  const renderFlexPayForm = () => {
    const amount = parseInt(flexPayAmount.replace(/,/g, "") || "0");
    const interest = calculateInterest(amount, repaymentPeriod);
    const totalToRepay = calculateTotalToRepay(amount, interest);
    const monthlyPayment = calculateMonthlyPayment(
      totalToRepay,
      repaymentPeriod
    );
    const remainingAmount = calculateRemainingAmount(ngnAmount, flexPayAmount);
    const isFlexPayAmountValid = amount <= creditLimit;

    return (
      <Box>
        <Text color="gray.600" mb={4}>
          Pay later
        </Text>

        <Text mb={2}>Amount to pay: ₦{ngnAmount}</Text>
        <Text mb={4}>
          Available credit line: ₦{formatAmount(creditLimit.toString())}
        </Text>

        <Divider mb={6} borderColor="gray.200" />

        <FormControl mb={6} isInvalid={!isFlexPayAmountValid}>
          <FormLabel fontSize="md" color="gray.700" fontWeight="normal" mb={2}>
            Enter amount you want to pay on credit
          </FormLabel>
          <Box width="200px">
            <Box
              borderWidth="1px"
              borderColor={isFlexPayAmountValid ? "#cbcbcb" : "red.300"}
              borderRadius="lg"
              p={3}
              bg="white"
            >
              <Flex align="center">
                <Text color="gray.600" fontSize="md" fontWeight="semibold">
                  NGN
                </Text>
                <Text color="gray.600" mx={2} fontWeight="semibold">
                  |
                </Text>
                <Input
                  variant="unstyled"
                  textAlign="right"
                  placeholder="0"
                  value={flexPayAmount}
                  onChange={(e) => handleFlexPayAmountChange(e.target.value)}
                  flex={1}
                  fontWeight="semibold"
                />
              </Flex>
            </Box>
            {!isFlexPayAmountValid && (
              <Text color="red.500" fontSize="sm" mt={1}>
                Amount exceeds your credit limit of ₦
                {formatAmount(creditLimit.toString())}
              </Text>
            )}
          </Box>
        </FormControl>

        <FormControl mb={6}>
          <FormLabel fontSize="md" color="gray.700" fontWeight="normal" mb={2}>
            Select repayment period
          </FormLabel>
          <Select
            value={repaymentPeriod}
            onChange={(e) => setRepaymentPeriod(parseInt(e.target.value))}
            borderColor="gray.200"
            borderRadius="lg"
            size="lg"
            width="200px"
          >
            {[1, 2, 3, 4, 5, 6].map((months) => (
              <option key={months} value={months}>
                {months} month{months > 1 ? "s" : ""}
              </option>
            ))}
          </Select>
        </FormControl>

        <Divider mb={6} borderColor="gray.200" />

        <Box mb={8}>
          <Text fontSize="lg" fontWeight="semibold" mb={4}>
            Estimated repayment terms
          </Text>
          <VStack spacing={4} align="stretch">
            <Flex justify="space-between">
              <Text color="gray.600">Amount</Text>
              <Text>₦{formatAmount(amount.toString())}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text color="gray.600">Total interest</Text>
              <Text>₦{formatAmount(interest.toString())}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text color="gray.600">Total amount to repay</Text>
              <Text>₦{formatAmount(totalToRepay.toString())}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text color="gray.600">Monthly payments</Text>
              <Text>₦{formatAmount(monthlyPayment.toString())}</Text>
            </Flex>
            <Flex justify="space-between">
              <Text color="gray.600">Balance to pay now</Text>
              <Text color={isFlexPayAmountValid ? "inherit" : "red.500"}>
                ₦{formatAmount(remainingAmount.toString())}
              </Text>
            </Flex>
          </VStack>
        </Box>

        <FormControl mb={8}>
          <Flex align="flex-start">
            <Checkbox
              id="terms"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              mr={2}
              mt={1}
            />
            <Text fontSize="sm" color="gray.600">
              I acknowledge the repayment terms above are estimated and
              non-binding. The legally binding loan terms and agreement must be
              subsequently duly completed with Alert Microfinance Bank for the
              funds to be paid to your supplier.
            </Text>
          </Flex>
        </FormControl>

        {/* <Box
          position="absolute"
          bottom={0}
          right={0}
          p={8}
          width="auto"
          bg="white"
        >
          <Button
            bg="black"
            color="white"
            size="lg"
            px={12}
            _hover={{ bg: "gray.800" }}
            onClick={handleCreatePayment}
            isLoading={isCreatingPayment}
            loadingText="Submitting"
            isDisabled={!termsAccepted || amount === 0 || amount > CREDIT_LIMIT}
          >
            {amount === 0 || !termsAccepted ? "Next" : "Submit"}
          </Button>
        </Box> */}
      </Box>
    );
  };

  const renderPaymentProofUpload = () => (
    <FormControl mt={6}>
      <FormLabel fontSize="md" color="gray.700" fontWeight="normal">
        Upload proof of payment
      </FormLabel>
      <input
        type="file"
        ref={proofFileInputRef}
        style={{ display: "none" }}
        onChange={handleProofFileUpload}
        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
      />
      <Box
        borderWidth={1}
        borderStyle="dashed"
        borderColor="gray.200"
        borderRadius="md"
        p={6}
        textAlign="center"
        cursor="pointer"
        onClick={triggerProofFileUpload}
        _hover={{ bg: "gray.50" }}
        width={isMobile ? "100%" : "40%"}
      >
        {selectedProofFile ? (
          <Box>
            <Text>✓ File uploaded</Text>
            <Text fontSize="sm" color="gray.500">
              {selectedProofFile.name}
            </Text>
          </Box>
        ) : (
          <Box>
            <Text mb={2}>↑</Text>
            <Text color="gray.600">Proof of payment</Text>
          </Box>
        )}
      </Box>
    </FormControl>
  );

  const renderBankDetails = () => (
    <Box>
      <Text color="gray.600" mb={4}>
        Bank transfer
      </Text>
      <Text mb={4}>
        To complete your transaction, please make your payment to the account
        below.
      </Text>
      <Text mb={2}>
        <b>Amount:</b> ₦{ngnAmount}
      </Text>

      <VStack align="start" spacing={1} mb={8}>
        <Text>
          <b>Account name:</b> Alert Yolu Account
        </Text>
        <Text>
          <b>Bank name:</b> Alert Microfinance Bank
        </Text>
        <Flex align="center">
          <Text>
            <b>Account number:</b> 13098093498
          </Text>
          <Button
            variant="ghost"
            size="sm"
            ml={2}
            onClick={() => copyToClipboard("13098093498", toast)}
            _hover={{ bg: "transparent" }}
          >
            <CopyIcon color="gray.500" w={4} h={4} />
          </Button>
        </Flex>
      </VStack>

      {renderPaymentProofUpload()}
    </Box>
  );

  const renderCashDepositDetails = () => (
    <Box>
      <Text color="gray.600" mb={4}>
        Cash deposit
      </Text>
      <Text mb={4}>
        To complete your transaction, please deposit amount to the account
        below.
      </Text>
      <Text mb={2}>
        <b>Amount:</b> <span>₦{ngnAmount}</span>
      </Text>
      <VStack align="start" spacing={1} mb={8}>
        <Text>
          <b>Account name:</b> Alert Yolu Account
        </Text>
        <Text>
          <b>Bank name:</b> Alert Microfinance Bank
        </Text>
        <Flex align="center">
          <Text>
            <b>Account number:</b> 13098093498
          </Text>
          <Button
            variant="ghost"
            size="sm"
            ml={2}
            onClick={() => copyToClipboard("13098093498", toast)}
            _hover={{ bg: "transparent" }}
          >
            <CopyIcon color="gray.500" w={4} h={4} />
          </Button>
        </Flex>
      </VStack>
    </Box>
  );

  const renderFinalStep = () => {
    // Add validation for flex pay submission
    const isFlexPayValid = () => {
      if (paymentMethod === "flexPay") {
        const amount = parseInt(flexPayAmount.replace(/,/g, "") || "0");
        return (
          creditLimit > 0 &&
          amount > 0 &&
          amount <= creditLimit &&
          repaymentPeriod > 0 &&
          termsAccepted
        );
      }

      return true;
    };

    return (
      <Box position="relative">
        <Box p={8} pb={isMobile ? 24 : 8}>
          <PaymentHeader
            currentStep={step}
            onStepClick={handleStepClick}
            completedSteps={completedSteps}
          />

          {paymentMethod === "flexPay" && renderFlexPayForm()}
          {paymentMethod === "bankTransfer" && renderBankDetails()}
          {paymentMethod === "cashDeposit" && renderCashDepositDetails()}
        </Box>

        <Box
          position={isMobile ? "fixed" : "absolute"}
          bottom={!isMobile ? -8 : 0}
          left={isMobile ? 0 : "auto"}
          right={0}
          p={isMobile ? 4 : 8}
          pb={isMobile ? 4 : 4}
          pt={isMobile ? 4 : 4}
          width={isMobile ? "100%" : "auto"}
          bg="white"
          borderTop={isMobile ? "1px solid" : "none"}
          borderColor="gray.200"
          zIndex={2}
        >
          <Button
            bg="black"
            color="white"
            size="lg"
            width={isMobile ? "100%" : "auto"}
            px={isMobile ? 4 : 12}
            _hover={{ bg: "gray.800" }}
            onClick={handleCreatePayment}
            isLoading={isCreatingPayment}
            loadingText="Submitting"
            isDisabled={!isFlexPayValid()}
          >
            Submit
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      size={isMobile ? "full" : "3xl"}
      motionPreset="slideInBottom"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        borderRadius="xl"
        maxH="90vh"
        my={0}
        mx={{ base: 0, md: 4 }}
        overflow="hidden"
      >
        {isMobile && (
          <Box position="absolute" top={4} right={4} zIndex={1}>
            <CloseIcon
              w={4}
              h={4}
              cursor="pointer"
              onClick={handleModalClose}
            />
          </Box>
        )}
        <ModalBody p={0} position="relative">
          <Box maxH="90vh" overflowY="auto" pb={16}>
            {step === 1 && renderInitialPaymentStep()}
            {step === 2 && renderPaymentMethodStep()}
            {step === 3 && renderFinalStep()}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaymentFlow;
